import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../Link'
import { Container } from '@/components/Container'
import * as styles from './Pagination.styles.scss'

const Pagination = ({ prevPage, nextPage }) => {
  return (
    <div>
      <Container className={styles.container}>
        <div>
          {prevPage && (
            <Link to={prevPage} className={styles.button}>
              <span className={styles.button__inner}>
                <span className={styles.button__text}>Prev Page</span>
              </span>
            </Link>
          )}
        </div>
        <div>
          {nextPage && (
            <Link to={nextPage} className={styles.button}>
              <span className={styles.button__inner}>
                <span className={styles.button__text}>Next Page</span>
              </span>
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

Pagination.defaultProps = {
  prevPage: null,
  nextPage: null,
}
Pagination.propTypes = {
  prevPage: PropTypes.string,
  nextPage: PropTypes.string,
}

export { Pagination }

import React from 'react'
import PropTypes from 'prop-types'

import { Pagination } from '@/components/Pagination'
import { Container } from '@/components/Container'
import { BlogPostContent } from '@/components/BlogPostContent'

import * as styles from './Blog.styles.scss'

const Blog = ({ posts, nextPage, prevPage }) => {
  return (
    <div className={styles.Blog}>
      {posts.map((post) => {
        return <BlogPostContent {...post} key={post.id} link={post.path} />
      })}
      {(prevPage || nextPage) && (
        <Container>
          <Pagination prevPage={prevPage} nextPage={nextPage} />
        </Container>
      )}
    </div>
  )
}

Blog.defaultProps = {
  posts: [],
  nextPage: null,
  prevPage: null,
}

Blog.propTypes = {
  posts: PropTypes.array,
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
}

export default Blog

import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { useSiteConfig } from '@/prismic/hooks/useSiteConfig'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import { getImage } from '@/prismic/utils/getImage'

export const useBlogPosts = () => {
  const siteConfig = useSiteConfig()

  const { allPrismicBlogPost } = useStaticQuery(
    graphql`
      query useBlogPosts {
        allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
          edges {
            node {
              id
              uid
              type
              data {
                title
                date(formatString: "DD MMM YYYY", locale: "en-GB")
                body {
                  ... on PrismicBlogPostBodyBodyText {
                    id
                    slice_type
                    primary {
                      content {
                        raw
                      }
                    }
                  }
                  ... on PrismicBlogPostBodyBigImage {
                    id
                    slice_type
                    items {
                      image {
                        fluid(maxWidth: 2500) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                        url
                        alt
                        dimensions {
                          width
                          height
                        }
                      }
                      credit {
                        raw
                      }
                    }
                  }
                  ... on PrismicBlogPostBodySmallImage {
                    id
                    slice_type
                    primary {
                      image {
                        fluid(maxWidth: 500) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                        url
                        alt
                        dimensions {
                          width
                          height
                        }
                      }
                    }
                  }
                  ... on PrismicBlogPostBodyVideo {
                    id
                    slice_type
                    primary {
                      url
                      aspect_ratio
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const posts = []
  allPrismicBlogPost.edges.forEach((post) => {
    posts.push({
      title: get(post, 'node.data.title'),
      id: post.node.uid,
      path: `${siteConfig.blog.slug}/${post.node.uid}`,
      image: getImage(post, 'node.data.image', 'fluid'),
      sections: sectionSerializer(post.node.data.body),
      date: post.node.data.date,
    })
  })

  return posts
}

import React from 'react'
import PropTypes from 'prop-types'
import { withPreview } from 'gatsby-source-prismic'
import { useBlogPosts } from '@/prismic/hooks/useBlogPosts'
import { linkResolver } from '@/prismic/utils/linkResolver'
import Blog from '@/templates/Blog'

const BlogPrismic = ({ pageContext }) => {
  const posts = useBlogPosts()
  const { title, pageNumber, numPages, uid, type, skip, limit } = pageContext

  return (
    <Blog
      title={title}
      posts={posts.slice(skip, skip + limit)}
      nextPage={
        pageNumber < numPages
          ? linkResolver({ uid, type, pageNumber: pageNumber + 1 })
          : null
      }
      prevPage={
        pageNumber > 1
          ? linkResolver({ uid, type, pageNumber: pageNumber - 1 })
          : null
      }
    />
  )
}

BlogPrismic.defaultProps = {
  pageContext: {},
}
BlogPrismic.propTypes = {
  pageContext: PropTypes.object,
}

export default withPreview(BlogPrismic)
